.app-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .app-logo-avatar {
    border-radius: 50%;
    overflow: hidden;
    margin: 5px 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .app-logo-title {
    font-weight: bold;
    font-size: 20px;
  }

  &.column-logo {
    flex-flow: column;
    margin-bottom: 20px;
  }

  &.row-logo {
    flex-flow: row;
    .app-logo-avatar {
      margin-right: 10px;
    }
    .app-logo-title {
      font-weight: 300;
    }
  }
}
